.app {
  text-align: center;
  height:200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position:relative;
  font-size: calc(10px + 2vmin);

  .app-header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: fixed;
    top:0;
    right:0;
    left:0;
    margin:0;
    z-index: 10;
  }

  .background{
    position: absolute;
    object-fit: cover;
    top:10vh;
    left:0;
    right:0;
    width:100%;
    height: 100vh;
  }

  .app-content{
    position: relative;
    top:10vh;
    display: grid;
    grid-template-columns: 50vw 50vw;
    height: 90vh;
    overflow: hidden;
    z-index: 1;

    .content{
      padding:2vh 2vw;
    }
  }

  .contact-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 15px;

    .phone-number{
        padding:2vw;
    }
    .email-address{
      padding:2vw;
    }
  }

  .copyright{
    margin-bottom:1vw;
    font-size: 16px;
    z-index: 1;
  }
}



